var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Inline } from '../Inline';
var labelStyles = function () { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  label: label;\n\n  align-items: center;\n"], ["\n  label: label;\n\n  align-items: center;\n"]))); };
var baseStyles = function () { return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  label: tabs;\n\n  width: 100%;\n"], ["\n  label: tabs;\n\n  width: 100%;\n"]))); };
export var Label = styled(Inline)(labelStyles);
export var TabsElement = styled('div')(baseStyles);
var templateObject_1, templateObject_2;
