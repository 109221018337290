var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
var baseStyles = function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  label: text;\n\n  margin-bottom: ", ";\n  font-weight: ", ";\n"], ["\n  label: text;\n\n  margin-bottom: ", ";\n  font-weight: ", ";\n"])), theme.spacings.mega, theme.font.weight.regular);
};
var alignmentStyles = function (_a) {
    var align = _a.align;
    if (!align) {
        return null;
    }
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    label: ", ";\n    text-align: ", ";\n  "], ["\n    label: ", ";\n    text-align: ", ";\n  "])), "text-align--" + align, align);
};
var boldStyles = function (_a) {
    var theme = _a.theme, _b = _a.isBold, isBold = _b === void 0 ? false : _b;
    return isBold && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    label: text--bold;\n    font-weight: ", ";\n  "], ["\n    label: text--bold;\n    font-weight: ", ";\n  "])), theme.font.weight.bold);
};
var casingStyles = function (_a) {
    var casing = _a.casing;
    if (!casing) {
        return null;
    }
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    label: ", ";\n    text-transform: ", ";\n  "], ["\n    label: ", ";\n    text-transform: ", ";\n  "])), "text-casing--" + casing, casing);
};
var colorStyles = function (_a) {
    var theme = _a.theme, color = _a.color;
    if (!color) {
        return null;
    }
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    label: ", ";\n    color: ", ";\n  "], ["\n    label: ", ";\n    color: ", ";\n  "])), "text-color--" + color, theme.colors[color]);
};
var decorationStyles = function (_a) {
    var decoration = _a.decoration;
    if (!decoration) {
        return null;
    }
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    label: ", ";\n    text-decoration: ", ";\n  "], ["\n    label: ", ";\n    text-decoration: ", ";\n  "])), "text-decoration--" + decoration, decoration);
};
var sizeStyles = function (_a) {
    var theme = _a.theme, _b = _a.size, size = _b === void 0 ? 'mega' : _b;
    if (!size) {
        return null;
    }
    var mobileSizeMap = {
        kilo: 'kilo',
        mega: 'mega',
        giga: 'mega',
    };
    return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    label: ", ";\n\n    font-size: ", ";\n    line-height: ", ";\n\n    ", " {\n      font-size: ", ";\n      line-height: ", ";\n    }\n  "], ["\n    label: ", ";\n\n    font-size: ", ";\n    line-height: ", ";\n\n    ", " {\n      font-size: ", ";\n      line-height: ", ";\n    }\n  "])), "text--" + size, theme.typography.text[mobileSizeMap[size]].fontSize, theme.typography.text[mobileSizeMap[size]].lineHeight, theme.media.kilo, theme.typography.text[size].fontSize, theme.typography.text[size].lineHeight);
};
var noMarginStyles = function (_a) {
    var _b = _a.noMargin, noMargin = _b === void 0 ? false : _b;
    return noMargin && css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    label: heading--no-margin;\n    margin-bottom: 0;\n  "], ["\n    label: heading--no-margin;\n    margin-bottom: 0;\n  "])));
};
var TextEl = styled('p', {
    shouldForwardProp: function (prop) {
        return isPropValid(prop) && prop !== 'size' && prop !== 'color';
    },
})(baseStyles, alignmentStyles, boldStyles, casingStyles, colorStyles, decorationStyles, sizeStyles, noMarginStyles);
/**
 * This component is used to render text and paragraphs.
 */
var Text = React.forwardRef(function (props, ref) { return (_jsx(TextEl, __assign({ ref: ref }, props), void 0)); });
Text.displayName = 'Text';
/**
 * @component
 */
export default Text;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
