import React from 'react';
/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 */
export function getValidChildren(children) {
    return React.Children.toArray(children).filter(function (child) {
        return React.isValidElement(child);
    });
}
