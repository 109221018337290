var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { css } from '@emotion/react';
var baseStyles = function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  label: tab__panel;\n\n  padding-top: ", ";\n  outline: 0;\n"], ["\n  label: tab__panel;\n\n  padding-top: ", ";\n  outline: 0;\n"])), theme.spacings.mega);
};
export var TabPanelEl = styled('div')(baseStyles);
var templateObject_1;
