var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
import * as S from './styled';
/**
 * A TabPanel to show Tabs content.
 */
var TabPanel = React.forwardRef(function (props, ref) {
    var _a = props.isSelected, isSelected = _a === void 0 ? false : _a;
    return (_jsx(S.TabPanelEl, __assign({ ref: ref, role: "tabpanel", "data-testid": "tab-panel", tabIndex: isSelected ? undefined : -1 }, props), void 0));
});
TabPanel.displayName = 'TabPanel';
/**
 * @component
 */
export default TabPanel;
