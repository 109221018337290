var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getValidChildren } from '../../utils/children';
var baseStyle = function (_a) {
    var theme = _a.theme, _b = _a.space, space = _b === void 0 ? 'giga' : _b;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  label: inline;\n\n  display: flex;\n  flex-direction: row;\n\n  div:not(:last-child) {\n    margin-right: ", ";\n  }\n"], ["\n  label: inline;\n\n  display: flex;\n  flex-direction: row;\n\n  div:not(:last-child) {\n    margin-right: ", ";\n  }\n"])), theme.spacings[space]);
};
var alignXStyles = function (_a) {
    var alignX = _a.alignX;
    if (!alignX) {
        return null;
    }
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    label: ", ";\n    justify-content: ", ";\n  "], ["\n    label: ", ";\n    justify-content: ", ";\n  "])), "inline-alignX--" + alignX, alignX);
};
var alignYStyles = function (_a) {
    var alignY = _a.alignY;
    if (!alignY) {
        return null;
    }
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    label: ", ";\n    align-items: ", ";\n  "], ["\n    label: ", ";\n    align-items: ", ";\n  "])), "inline-alignY--" + alignY, alignY);
};
var wrapStyles = function (_a) {
    var wrap = _a.wrap;
    if (!wrap) {
        return null;
    }
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    label: ", ";\n    flex-wrap: ", ";\n  "], ["\n    label: ", ";\n    flex-wrap: ", ";\n  "])), "stack-wrap--" + wrap, wrap);
};
var InlineEl = styled('div')(baseStyle, alignXStyles, alignYStyles, wrapStyles);
/**
 * Inline is a layout component to consistently
 * control the horizontal white space between elements.
 */
var Inline = React.forwardRef(function (props, ref) {
    var children = props.children;
    var validChildren = getValidChildren(children);
    return (_jsx(InlineEl, __assign({ ref: ref }, props, { children: validChildren.map(function (child, index) { return (_jsx("div", { children: child }, index)); }) }), void 0));
});
/**
 * @component
 */
export default Inline;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
