var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { text, subHeading } from '../../../../styles/style-helpers';
var APPEARANCE_MAP = {
    critical: 'r500',
    info: 'n500',
    primary: 'p600',
    secondary: 'v500',
    warning: 'y500',
};
var baseStyles = function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  label: tab;\n\n  display: block;\n\n  width: 100%;\n  padding: ", " ", ";\n  font-weight: ", ";\n  margin-bottom: -1px;\n  overflow: hidden;\n\n  ", ";\n\n  color: ", ";\n  text-decoration: none;\n  white-space: nowrap;\n\n  cursor: pointer;\n\n  background-color: transparent;\n  border: 0;\n  border-bottom: 3px solid transparent;\n  outline: 0;\n\n  transition: 0.3s;\n\n  &:hover {\n    transition: 0.3s;\n    background-color: ", ";\n  }\n\n  &:active {\n    z-index: 1;\n    background-color: ", ";\n  }\n\n  ", " {\n    ", ";\n  }\n"], ["\n  label: tab;\n\n  display: block;\n\n  width: 100%;\n  padding: ", " ", ";\n  font-weight: ", ";\n  margin-bottom: -1px;\n  overflow: hidden;\n\n  ", ";\n\n  color: ", ";\n  text-decoration: none;\n  white-space: nowrap;\n\n  cursor: pointer;\n\n  background-color: transparent;\n  border: 0;\n  border-bottom: 3px solid transparent;\n  outline: 0;\n\n  transition: 0.3s;\n\n  &:hover {\n    transition: 0.3s;\n    background-color: ", ";\n  }\n\n  &:active {\n    z-index: 1;\n    background-color: ", ";\n  }\n\n  ", " {\n    ", ";\n  }\n"])), theme.spacings.bit, theme.spacings.giga, theme.font.weight.regular, subHeading('mega')(theme), theme.colors.n400, theme.colors.n100, theme.colors.n200, theme.media.mega, text('mega')(theme));
};
var selectedStyles = function (_a) {
    var theme = _a.theme, isSelected = _a.isSelected, appearance = _a.appearance;
    var currentColor = APPEARANCE_MAP[appearance || 'primary'];
    return (isSelected && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      label: tab--selected;\n      position: relative;\n\n      color: ", ";\n      border-color: ", ";\n      font-weight: ", ";\n    "], ["\n      label: tab--selected;\n      position: relative;\n\n      color: ", ";\n      border-color: ", ";\n      font-weight: ", ";\n    "])), theme.colors.n900, theme.colors[currentColor], theme.font.weight.semibold));
};
export var TabElement = styled('button')(baseStyles, selectedStyles);
var templateObject_1, templateObject_2;
