var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var type = 'light';
var white = '#FFFFFF';
var black = '#000000';
var blues = {
    b100: '#ECFCFD',
    b200: '#B7EEF5',
    b300: '#84DBEB',
    b400: '#54C4DE',
    b500: '#25A8D0',
    b600: '#2282A8',
    b700: '#1E5F82',
    b800: '#18415D',
    b900: '#102739',
};
var flamingos = {
    f100: '#FBECFF',
    f200: '#FADCFF',
    f300: '#F6BDFF',
    f400: '#D078DA',
    f500: '#B92ECB',
    f600: '#9A21AA',
    f700: '#791186',
    f800: '#4E1155',
    f900: '#350D3A',
};
var greens = {
    g100: '#EAF3E2',
    g200: '#CBE3B5',
    g300: '#ADD585',
    g400: '#91C854',
    g500: '#78BE20',
    g600: '#41A017',
    g700: '#158110',
    g800: '#0A601B',
    g900: '#053E1F',
};
var neutrals = {
    n100: '#EEEEF0',
    n200: '#D3D2D6',
    n300: '#BAB6BC',
    n400: '#A19BA2',
    n500: '#887F87',
    n600: '#6F676C',
    n700: '#554F52',
    n800: '#3C3738',
    n900: '#222020',
};
var oranges = {
    o100: '#FFF5EB',
    o200: '#FFDAB2',
    o300: '#FFC170',
    o400: '#FFAB48',
    o500: '#FC961E',
    o600: '#C86F21',
    o700: '#974F20',
    o800: '#6A351C',
    o900: '#401F15',
};
var reds = {
    r100: '#FEEDEE',
    r200: '#FAB9BC',
    r300: '#F38789',
    r400: '#EA5D58',
    r500: '#DF382B',
    r600: '#B42A27',
    r700: '#8B2226',
    r800: '#641B21',
    r900: '#3E1219',
};
var violets = {
    v100: '#E9D0FF',
    v200: '#C48FF3',
    v300: '#AD63F0',
    v400: '#9040E6',
    v500: '#7808CF',
    v600: '#6C0CAE',
    v700: '#570887',
    v800: '#480870',
    v900: '#350C52',
};
var yellows = {
    y100: '#FFF8E2',
    y200: '#FFEBAF',
    y300: '#FEE07D',
    y400: '#FCD64C',
    y500: '#F8CC1C',
    y600: '#C9A21B',
    y700: '#9C7B18',
    y800: '#705613',
    y900: '#46350D',
};
var primary = {
    p100: greens.g100,
    p200: greens.g200,
    p300: greens.g300,
    p400: greens.g400,
    p500: greens.g500,
    p600: greens.g600,
    p700: greens.g700,
    p800: greens.g800,
    p900: greens.g900,
};
var misc = {
    shadow: 'rgba(60, 55, 56, 0.07)',
    overlay: 'rgba(39, 44, 54, 0.3)',
    background: '#F8F8F8',
    danger: reds.r700,
    info: neutrals.n600,
    success: greens.g700,
    warning: yellows.y600,
};
export var colors = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ white: white,
    black: black }, neutrals), blues), flamingos), greens), yellows), reds), oranges), violets), primary), misc);
export * from './shared';
