import React from 'react';
import { useSafeLayoutEffect } from './use-safe-layout-effect';
/**
 * NOTE:
 * https://github.com/reach/reach-ui/blob/develop/packages/auto-id/src/index.tsx
 */
var handoffComplete = false;
var id = 0;
var genId = function () { return ++id; };
/**
 * React hook to generate unique id
 *
 * @param idProp the external id passed from the user
 * @param prefix prefix to append before the id
 */
export function useId(idProp, prefix) {
    var initialId = idProp || (handoffComplete ? genId() : null);
    var _a = React.useState(initialId), uid = _a[0], setUid = _a[1];
    useSafeLayoutEffect(function () {
        if (uid === null)
            setUid(genId());
    }, []);
    React.useEffect(function () {
        if (handoffComplete === false) {
            handoffComplete = true;
        }
    }, []);
    var id = uid != null ? uid.toString() : undefined;
    return (prefix ? prefix + "-" + id : id);
}
