var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
var COLORS_MAP = {
    critical: 'r500',
    info: 'n500',
    primary: 'g500',
    secondary: 'v500',
    warning: 'y500',
};
var baseStyles = function () { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  label: icon;\n  display: block;\n"], ["\n  label: icon;\n  display: block;\n"]))); };
var sizeStyles = function (_a) {
    var theme = _a.theme, _b = _a.size, size = _b === void 0 ? 'mega' : _b;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  label: ", ";\n\n  width: ", ";\n  height: ", ";\n"], ["\n  label: ", ";\n\n  width: ", ";\n  height: ", ";\n"])), "icon--size-" + theme.iconSizes[size], theme.iconSizes[size], theme.iconSizes[size]);
};
var colorStyles = function (_a) {
    var theme = _a.theme, color = _a.color;
    var currentColor = COLORS_MAP[color];
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    label: ", ";\n    color: ", ";\n  "], ["\n    label: ", ";\n    color: ", ";\n  "])), "icon--color-" + color, color ? theme.colors[currentColor] : 'currentColor');
};
var IconElement = styled('svg', {
    shouldForwardProp: function (prop) { return isPropValid(prop) && prop !== 'color'; },
})(baseStyles, colorStyles, sizeStyles);
/**
 * A wrapper to usage with Icon Library
 */
var Icon = React.forwardRef(function (props, ref) {
    var _a = props.role, role = _a === void 0 ? 'presentation' : _a;
    return _jsx(IconElement, __assign({ role: role, ref: ref }, props), void 0);
});
Icon.displayName = 'Icon';
/**
 * @component
 */
export default Icon;
var templateObject_1, templateObject_2, templateObject_3;
