var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React, { useEffect, useState, useRef, forwardRef, cloneElement, } from 'react';
import { Icon } from '../../components/Icon';
import { isArrowLeft, isArrowRight } from '../../utils/key-down-arrows';
import { Tab } from './components/Tab';
import { TabList } from './components/TabList';
import { TabPanel } from './components/TabPanel';
import * as S from './styled';
/**
 * A Tabs for display some info.
 */
var Tabs = forwardRef(function (props, ref) {
    var children = props.children, selectedIdProp = props.selectedId, _a = props.defaultSelectedId, defaultSelectedId = _a === void 0 ? null : _a, appearance = props.appearance, items = props.items, onTabClick = props.onTabClick, onTabKeyDown = props.onTabKeyDown, otherProps = __rest(props, ["children", "selectedId", "defaultSelectedId", "appearance", "items", "onTabClick", "onTabKeyDown"]);
    var _b = useState(defaultSelectedId), selectedId = _b[0], setSelectedId = _b[1];
    var _c = useState([]), childrenArray = _c[0], setChildrenArray = _c[1];
    var tabListRef = useRef(null);
    var tabsRef = React.useRef([]);
    useEffect(function () {
        if (items) {
            var newChildrenArray = items.map(function (_a) {
                var id = _a.id, panel = _a.panel, otherItemProps = __rest(_a, ["id", "panel"]);
                return (_jsx(TabPanel, __assign({ id: id }, otherItemProps, { children: panel }), "panel-" + id));
            });
            setChildrenArray(newChildrenArray);
        }
    }, [items]);
    useEffect(function () {
        if (children) {
            var newChildrenArray = Array.isArray(children) ? children : [children];
            setChildrenArray(newChildrenArray);
        }
    }, [children]);
    useEffect(function () {
        var _a, _b;
        if (!selectedId && ((_b = (_a = childrenArray[0]) === null || _a === void 0 ? void 0 : _a.props) === null || _b === void 0 ? void 0 : _b.id)) {
            setSelectedId(childrenArray[0].props.id);
        }
    }, [childrenArray, selectedId]);
    var handleTabKeyDown = function (event) {
        if (typeof onTabKeyDown === 'function') {
            onTabKeyDown(event);
            return;
        }
        var keyIsArrowLeft = isArrowLeft(event);
        var keyIsArrowRight = isArrowRight(event);
        if (!keyIsArrowLeft && !keyIsArrowRight) {
            return;
        }
        var currentIndex = tabsRef.current.findIndex(function (item) { return item.id === selectedId; });
        var previousIndex = Math.max(0, currentIndex - 1);
        var nextIndex = Math.min(tabsRef.current.length - 1, currentIndex + 1);
        if (keyIsArrowLeft) {
            var _a = tabsRef.current[previousIndex], element = _a.element, id = _a.id;
            element.focus();
            setSelectedId(id);
        }
        if (keyIsArrowRight) {
            var _b = tabsRef.current[nextIndex], element = _b.element, id = _b.id;
            element.focus();
            setSelectedId(id);
        }
    };
    var handleTabClick = function (event, id) {
        if (typeof onTabClick === 'function') {
            onTabClick(event, id);
        }
        else {
            setSelectedId(id);
        }
    };
    var currentSelectedId = selectedIdProp || selectedId;
    return (_jsxs(S.TabsElement, __assign({ ref: ref }, { children: [_jsx(TabList, __assign({ ref: tabListRef }, otherProps, { children: childrenArray.map(function (_a, index) {
                    var _b = _a.props, id = _b.id, label = _b.label, icon = _b.icon;
                    var isSelected = currentSelectedId === id;
                    return (_jsx(Tab, __assign({ id: "tab-" + id, "data-testid": "tab-element", isSelected: isSelected, "aria-controls": "panel-" + id, appearance: appearance, onClick: function (event) { return handleTabClick(event, id); }, onKeyDown: handleTabKeyDown, ref: function (element) {
                            tabsRef.current[index] = {
                                element: element,
                                id: id,
                            };
                        } }, { children: icon ? (_jsxs(S.Label, __assign({ space: "byte", alignX: "center", wrap: "nowrap" }, { children: [_jsx(Icon, { as: icon }, void 0),
                                _jsx("div", { children: label }, void 0)] }), void 0)) : (label) }), "tab-" + id));
                }) }), void 0),
            childrenArray.map(function (panel) {
                return cloneElement(panel, {
                    hidden: currentSelectedId !== panel.props.id,
                    key: "panel-" + panel.props.id,
                });
            })] }), void 0));
});
Tabs.displayName = 'Tabs';
/**
 * @component
 */
export default Tabs;
