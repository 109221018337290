var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
function isTheme(args) {
    return (args.theme === undefined);
}
var getTheme = function (args) {
    return isTheme(args) ? args : args.theme;
};
/**
 * Typography
 */
var typography = function (type, size) {
    return function (args) {
        var theme = getTheme(args);
        var _a = theme.typography[type][size], fontSize = _a.fontSize, lineHeight = _a.lineHeight;
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      font-size: ", ";\n      line-height: ", ";\n    "], ["\n      font-size: ", ";\n      line-height: ", ";\n    "])), fontSize, lineHeight);
    };
};
export var heading = function (size) {
    return function (args) {
        return typography('headings', size)(args);
    };
};
export var subHeading = function (size) {
    return function (args) {
        return typography('subHeadings', size)(args);
    };
};
export var text = function (size) {
    return function (args) {
        return typography('text', size)(args);
    };
};
export var headingKilo = typography('headings', 'kilo');
export var headingMega = typography('headings', 'mega');
export var headingGiga = typography('headings', 'giga');
export var headingTera = typography('headings', 'tera');
export var headingPeta = typography('headings', 'peta');
export var headingExa = typography('headings', 'exa');
export var headingZetta = typography('headings', 'zetta');
export var subHeadingKilo = typography('subHeadings', 'kilo');
export var subHeadingMega = typography('subHeadings', 'mega');
export var textKilo = typography('text', 'kilo');
export var textMega = typography('text', 'mega');
export var textGiga = typography('text', 'giga');
/**
 * Shadow
 */
export var shadowSingle = function (args) {
    var theme = getTheme(args);
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    box-shadow: 0 0 0 1px ", ",\n      0 0 1px 0 ", ", 0 2px 2px 0 ", ";\n  "], ["\n    box-shadow: 0 0 0 1px ", ",\n      0 0 1px 0 ", ", 0 2px 2px 0 ", ";\n  "])), theme.colors.shadow, theme.colors.shadow, theme.colors.shadow);
};
export var shadowDouble = function (args) {
    var theme = getTheme(args);
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    box-shadow: 0 0 0 1px ", ",\n      0 2px 2px 0 ", ", 0 4px 4px 0 ", ";\n  "], ["\n    box-shadow: 0 0 0 1px ", ",\n      0 2px 2px 0 ", ", 0 4px 4px 0 ", ";\n  "])), theme.colors.shadow, theme.colors.shadow, theme.colors.shadow);
};
export var shadowTriple = function (args) {
    var theme = getTheme(args);
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    box-shadow: 0 0 0 1px ", ",\n      0 4px 4px 0 ", ", 0 8px 8px 0 ", ";\n  "], ["\n    box-shadow: 0 0 0 1px ", ",\n      0 4px 4px 0 ", ", 0 8px 8px 0 ", ";\n  "])), theme.colors.shadow, theme.colors.shadow, theme.colors.shadow);
};
export var shadow = function (elevation) {
    return function (args) {
        var theme = getTheme(args);
        var depths = {
            single: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        box-shadow: 0 0 0 1px ", ",\n          0 0 1px 0 ", ", 0 2px 2px 0 ", ";\n      "], ["\n        box-shadow: 0 0 0 1px ", ",\n          0 0 1px 0 ", ", 0 2px 2px 0 ", ";\n      "])), theme.colors.shadow, theme.colors.shadow, theme.colors.shadow),
            double: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        box-shadow: 0 0 0 1px ", ",\n          0 2px 2px 0 ", ", 0 4px 4px 0 ", ";\n      "], ["\n        box-shadow: 0 0 0 1px ", ",\n          0 2px 2px 0 ", ", 0 4px 4px 0 ", ";\n      "])), theme.colors.shadow, theme.colors.shadow, theme.colors.shadow),
            triple: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        box-shadow: 0 0 0 1px ", ",\n          0 4px 4px 0 ", ", 0 8px 8px 0 ", ";\n      "], ["\n        box-shadow: 0 0 0 1px ", ",\n          0 4px 4px 0 ", ", 0 8px 8px 0 ", ";\n      "])), theme.colors.shadow, theme.colors.shadow, theme.colors.shadow),
        };
        return depths[elevation];
    };
};
/**
 * Hide the browser scrollbar
 * on a scrollable element, e.g. with overflow.
 */
export var hideScrollbar = function () { return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"], ["\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"
    /**
     * A helper to used with inputs
     * display validation styles
     */
]))); };
/**
 * A helper to used with inputs
 * display validation styles
 */
export var inputOutline = function (args) {
    var theme = getTheme(args);
    var options = isTheme(args)
        ? {
            state: undefined,
            isDisabled: false,
        }
        : args;
    var colors;
    switch (true) {
        case options.isDisabled: {
            return css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n        color: ", ";\n        pointer-events: none;\n\n        background: ", ";\n        box-shadow: 0 0 0 1px ", ";\n      "], ["\n        color: ", ";\n        pointer-events: none;\n\n        background: ", ";\n        box-shadow: 0 0 0 1px ", ";\n      "])), theme.colors.n500, theme.colors.n100, theme.colors.n200);
        }
        case options.state === 'invalid': {
            colors = {
                default: theme.colors.r600,
                hover: theme.colors.r700,
                focus: theme.colors.r100,
                active: theme.colors.r700,
            };
            break;
        }
        case options.state === 'valid': {
            colors = {
                default: theme.colors.g600,
                hover: theme.colors.g700,
                focus: theme.colors.g100,
                active: theme.colors.g700,
            };
            break;
        }
        case options.state === 'warning': {
            colors = {
                default: theme.colors.y500,
                hover: theme.colors.y600,
                focus: theme.colors.y100,
                active: theme.colors.y600,
            };
            break;
        }
        default: {
            colors = {
                default: theme.colors.n100,
                hover: theme.colors.p600,
                focus: theme.colors.p100,
                active: theme.colors.p700,
            };
            break;
        }
    }
    return css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    border-color: ", ";\n    border-style: solid;\n    border-width: ", ";\n\n    &:hover {\n      border-color: ", ";\n    }\n\n    &:focus {\n      border-color: ", ";\n      box-shadow: 0 0 0 3px ", ";\n    }\n\n    &:active {\n      border-color: ", ";\n      box-shadow: 0 0 0 3px ", ";\n    }\n  "], ["\n    border-color: ", ";\n    border-style: solid;\n    border-width: ", ";\n\n    &:hover {\n      border-color: ", ";\n    }\n\n    &:focus {\n      border-color: ", ";\n      box-shadow: 0 0 0 3px ", ";\n    }\n\n    &:active {\n      border-color: ", ";\n      box-shadow: 0 0 0 3px ", ";\n    }\n  "])), colors.default, theme.borderWidth.kilo, colors.hover, colors.hover, colors.focus, colors.active, colors.focus);
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
