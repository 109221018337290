import { createMediaQueries } from '../utils/media';
export var borderRadius = {
    kilo: '2px',
    mega: '4px',
    giga: '8px',
    tera: '12px',
    circle: '100%',
};
export var borderWidth = {
    kilo: '1px',
    mega: '2px',
};
export var font = {
    family: {
        default: 'Leroy Merlin Sans, sans-serif',
    },
    weight: {
        regular: '400',
        semibold: '600',
        bold: '800',
    },
};
export var breakpoints = {
    untilKilo: 1,
    kilo: 36,
    mega: 48,
    giga: 62,
    tera: 75,
};
export var grid = {
    container: {
        untilKilo: 'full',
        kilo: 33.75,
        mega: 44.25,
        giga: 59.75,
        tera: 73.625,
    },
    padding: {
        untilKilo: 1,
        kilo: 1.25,
        mega: 1,
        giga: 1.25,
        tera: 1.375,
    },
    columns: {
        untilKilo: 4,
        kilo: 4,
        mega: 8,
        giga: 12,
        tera: 12,
    },
    gutter: {
        untilKilo: 1,
        kilo: 1.25,
        mega: 1,
        giga: 1.25,
        tera: 1.375,
    },
    breakpoints: breakpoints,
};
export var iconSizes = {
    byte: '8px',
    kilo: '12px',
    mega: '16px',
    giga: '20px',
};
export var spacings = {
    bit: '4px',
    byte: '8px',
    kilo: '12px',
    mega: '16px',
    giga: '24px',
    tera: '32px',
    peta: '40px',
    exa: '48px',
    zetta: '56px',
};
export var typography = {
    headings: {
        kilo: {
            fontSize: '17px',
            lineHeight: '25px',
        },
        mega: {
            fontSize: '19px',
            lineHeight: '28px',
        },
        giga: {
            fontSize: '22px',
            lineHeight: '28px',
        },
        tera: {
            fontSize: '24px',
            lineHeight: '31px',
        },
        peta: {
            fontSize: '28px',
            lineHeight: '33px',
        },
        exa: {
            fontSize: '36px',
            lineHeight: '43px',
        },
        zetta: {
            fontSize: '42px',
            lineHeight: '50px',
        },
    },
    subHeadings: {
        kilo: {
            fontSize: '12px',
            lineHeight: '18px',
        },
        mega: {
            fontSize: '14px',
            lineHeight: '21px',
        },
    },
    text: {
        kilo: {
            fontSize: '13px',
            lineHeight: '20px',
        },
        mega: {
            fontSize: '16px',
            lineHeight: '24px',
        },
        giga: {
            fontSize: '18px',
            lineHeight: '27px',
        },
    },
};
export var transition = {
    enter: '200ms',
    leave: '300ms',
};
export var zIndex = {
    default: 0,
    backdrop: 900,
    modal: 1000,
};
export var media = createMediaQueries(grid.breakpoints);
