var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Label } from '../Label';
import { ValidationHint } from '../ValidationHint';
import { text, inputOutline } from '../../styles/style-helpers';
import { useId } from '../../hooks/use-id';
import { textKilo, textMega, textGiga } from '../../styles/style-helpers';
var fieldStyles = function () { return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  label: input__field;\n"], ["\n  label: input__field;\n"]))); };
var Field = styled('div')(fieldStyles);
var containerStyles = function () { return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  label: input__container;\n\n  position: relative;\n  display: flex;\n  align-items: center;\n"], ["\n  label: input__container;\n\n  position: relative;\n  display: flex;\n  align-items: center;\n"]))); };
var Container = styled('div')(containerStyles);
var inputStyles = function (_a) {
    var theme = _a.theme;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  label: input;\n\n  width: 100%;\n  box-sizing: border-box;\n  padding: calc(", " - ", ");\n\n  ", "\n  color: ", ";\n  text-align: left;\n\n  border: 0;\n  border-radius: ", ";\n  outline: 0;\n\n  appearance: none;\n  transition: color ", ",\n    border-color ", ", box-shadow ", ";\n\n  &::placeholder {\n    color: ", ";\n    transition: color ", ";\n  }\n"], ["\n  label: input;\n\n  width: 100%;\n  box-sizing: border-box;\n  padding: calc(", " - ", ");\n\n  ", "\n  color: ", ";\n  text-align: left;\n\n  border: 0;\n  border-radius: ", ";\n  outline: 0;\n\n  appearance: none;\n  transition: color ", ",\n    border-color ", ", box-shadow ", ";\n\n  &::placeholder {\n    color: ", ";\n    transition: color ", ";\n  }\n"])), theme.spacings.kilo, theme.borderWidth.kilo, text('mega')(theme), theme.colors.n900, theme.borderRadius.giga, theme.transition.enter, theme.transition.enter, theme.transition.enter, theme.colors.n300, theme.transition.enter);
};
var invalidStyles = function (_a) {
    var theme = _a.theme, state = _a.state, disabled = _a.disabled;
    return !disabled &&
        state === 'invalid' && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    label: label--invalid;\n\n    &::placeholder {\n      color: ", ";\n    }\n  "], ["\n    label: label--invalid;\n\n    &::placeholder {\n      color: ", ";\n    }\n  "])), theme.colors.r700);
};
var warningStyles = function (_a) {
    var theme = _a.theme, state = _a.state, disabled = _a.disabled;
    return !disabled &&
        state === 'warning' && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    label: label--warning;\n\n    &::placeholder {\n      color: ", ";\n    }\n  "], ["\n    label: label--warning;\n\n    &::placeholder {\n      color: ", ";\n    }\n  "])), theme.colors.y700);
};
var readOnlyStyles = function (_a) {
    var theme = _a.theme, readOnly = _a.readOnly;
    return readOnly && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    label: input--readonly;\n\n    background: ", ";\n    box-shadow: 0 0 0 1px ", ";\n  "], ["\n    label: input--readonly;\n\n    background: ", ";\n    box-shadow: 0 0 0 1px ", ";\n  "])), theme.colors.n100, theme.colors.n200);
};
var prefixStyles = function (_a) {
    var theme = _a.theme, hasPrefix = _a.hasPrefix;
    return hasPrefix && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    label: input--prefix;\n    padding-left: ", ";\n  "], ["\n    label: input--prefix;\n    padding-left: ", ";\n  "])), theme.spacings.exa);
};
var suffixStyles = function (_a) {
    var theme = _a.theme, hasSuffix = _a.hasSuffix;
    return hasSuffix && css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    label: input--suffix;\n    padding-right: ", ";\n  "], ["\n    label: input--suffix;\n    padding-right: ", ";\n  "])), theme.spacings.exa);
};
var iconStyles = function (theme) { return css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n\n  width: calc(", " - ", ");\n  height: calc(", " - ", ");\n\n  box-sizing: border-box;\n  padding: ", ";\n\n  color: ", ";\n  pointer-events: none;\n"], ["\n  position: absolute;\n\n  width: calc(", " - ", ");\n  height: calc(", " - ", ");\n\n  box-sizing: border-box;\n  padding: ", ";\n\n  color: ", ";\n  pointer-events: none;\n"])), theme.spacings.exa, theme.borderWidth.mega, theme.spacings.exa, theme.borderWidth.mega, theme.spacings.kilo, theme.colors.n700); };
export var iconPrefixStyles = function (theme) { return css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  label: input__prefix;\n\n  ", ";\n"], ["\n  label: input__prefix;\n\n  ", ";\n"])), iconStyles(theme)); };
export var iconSuffixStyles = function (theme) { return css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  label: input__suffix;\n\n  right: 0;\n  ", ";\n"], ["\n  label: input__suffix;\n\n  right: 0;\n  ", ";\n"])), iconStyles(theme)); };
var sizeStyles = function (_a) {
    var theme = _a.theme, _b = _a.size, size = _b === void 0 ? 'mega' : _b;
    var sizes = {
        kilo: css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      ", ";\n      padding: ", ";\n    "], ["\n      ", ";\n      padding: ", ";\n    "])), textKilo(theme), theme.spacings.byte),
        mega: css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      ", ";\n      padding: ", ";\n    "], ["\n      ", ";\n      padding: ", ";\n    "])), textMega(theme), theme.spacings.kilo),
        giga: css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      ", ";\n      padding: ", ";\n    "], ["\n      ", ";\n      padding: ", ";\n    "])), textGiga(theme), theme.spacings.mega),
    };
    return css(__assign({ label: "select--" + size }, sizes[size]));
};
var InputEl = styled('input', {
    shouldForwardProp: function (prop) { return isPropValid(prop) && prop !== 'onClear'; },
})(inputStyles, invalidStyles, warningStyles, readOnlyStyles, sizeStyles, prefixStyles, suffixStyles, inputOutline);
var TextField = React.forwardRef(function (props, ref) {
    var id = props.id, label = props.label, value = props.value, hint = props.hint, state = props.state, isDisabled = props.isDisabled, isReadOnly = props.isReadOnly, isRequired = props.isRequired, size = props.size, RenderPrefix = props.renderPrefix, RenderSuffix = props.renderSuffix;
    var uuid = useId();
    var fieldId = id || "textfield-" + uuid;
    var labelId = fieldId + "-label";
    var prefix = RenderPrefix && _jsx(RenderPrefix, { css: iconPrefixStyles }, void 0);
    var suffix = RenderSuffix && _jsx(RenderSuffix, { css: iconSuffixStyles }, void 0);
    var hasPrefix = Boolean(prefix);
    var hasSuffix = Boolean(suffix);
    var isInvalid = state === 'invalid';
    return (_jsxs(Field, { children: [_jsx(Label, __assign({ id: labelId, htmlFor: fieldId, isDisabled: isDisabled }, { children: label }), void 0),
            _jsxs(Container, { children: [prefix, _jsx(InputEl, __assign({ id: fieldId, ref: ref, value: value, state: state, disabled: isDisabled, readOnly: isReadOnly, size: size, hasPrefix: hasPrefix, hasSuffix: hasSuffix, "aria-required": isRequired, "aria-invalid": isInvalid }, props), void 0), suffix] }, void 0),
            _jsx(ValidationHint, { hint: hint, state: state, isDisabled: isDisabled }, void 0)] }, void 0));
});
TextField.displayName = 'TextField';
/**
 * @component
 */
export default TextField;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
