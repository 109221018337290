var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { hideScrollbar } from '../../../../styles/style-helpers';
var wrapperStyles = function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  label: tablist-wrapper;\n\n  display: flex;\n  overflow-x: auto;\n\n  ", ";\n\n  ", " {\n    overflow-x: unset;\n  }\n"], ["\n  label: tablist-wrapper;\n\n  display: flex;\n  overflow-x: auto;\n\n  ", ";\n\n  ", " {\n    overflow-x: unset;\n  }\n"])), hideScrollbar(), theme.media.mega);
};
var tabListStyles = function (_a) {
    var theme = _a.theme;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  label: tablist;\n\n  display: flex;\n  width: 100%;\n\n  border-bottom: solid 1px ", ";\n"], ["\n  label: tablist;\n\n  display: flex;\n  width: 100%;\n\n  border-bottom: solid 1px ", ";\n"])), theme.colors.n200);
};
export var Wrapper = styled('div')(wrapperStyles);
export var TabListEl = styled('div')(tabListStyles);
var templateObject_1, templateObject_2;
