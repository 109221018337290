var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { subHeadingKilo } from '../../styles/style-helpers';
export var baseStyles = function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  label: label;\n\n  display: block;\n  margin-bottom: ", ";\n\n  ", ";\n  font-weight: ", ";\n  color: ", ";\n"], ["\n  label: label;\n\n  display: block;\n  margin-bottom: ", ";\n\n  ", ";\n  font-weight: ", ";\n  color: ", ";\n"])), theme.spacings.bit, subHeadingKilo(theme), theme.font.weight.bold, theme.colors.n900);
};
export var disbledStyles = function (_a) {
    var theme = _a.theme, isDisabled = _a.isDisabled;
    return isDisabled && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    label: label--disabled;\n    color: ", ";\n  "], ["\n    label: label--disabled;\n    color: ", ";\n  "
        /**
         * Label to usage with components form
         */
    ])), theme.colors.n500);
};
/**
 * Label to usage with components form
 */
var LabelEl = styled('label')(baseStyles, disbledStyles);
var Label = React.forwardRef(function (props, ref) { return (_jsx(LabelEl, __assign({ ref: ref }, props), void 0)); });
/**
 * @component
 */
export default Label;
var templateObject_1, templateObject_2;
